.toggle {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.toggle label {
    margin-right: 1rem;
}

.toggle input[type="checkbox"] {
    position: relative;
    width: 4rem;
    height: 2rem;
    -webkit-appearance: none;
    background-color: #e6e6e6;
    border-radius: 2rem;
    box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.1);
    outline: none;
    transition: background-color 0.3s ease;
}

.toggle input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.toggle input[type="checkbox"]:checked {
    background-color: var(--color-blue);
}

.toggle input[type="checkbox"]:checked::before {
    transform: translateX(2rem);
}