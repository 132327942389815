@tailwind base;
@tailwind components;
@tailwind utilities;

@import "abstracts/fonts.css";
@import "theme/theme.css";
@import "loading/loading.css";
@import "modal/modal.css";
@import "toggle/toggle.css";
@import "modal/cookies-modal.css";

html {
  scroll-behavior: smooth;
  scroll-padding: 135px;
}

body {
  margin: 0;
  font-family: Roboto, Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:has(.header-active) {
  overflow: hidden;
}

.about-section-step {
  grid-template-rows: auto auto 1fr;
}

.button-animation {
  transition: background-color 200ms ease-in-out;
}

.contact-form-input {
  box-shadow: 0px 3px 6px #2292cc4c;
}

.terms-and-policy-paragraph a {
  color: var(--color-blue);
}

.grecaptcha-badge {
  visibility: hidden;
}

.layout-image:has(.error-page),.layout-image:has(.static-page){
  background-image: none !important;
  background-color: var(--color-background-off);
}

.min-h-half-screen{
  min-height: 50vh;
}

#open-cookies-modal-button{
  padding:0.75rem 2rem;
  background-color: var(--color-blue);
  transition: 200ms;
  border-radius: 99px;
  color: white;
  font-weight: bold;
  letter-spacing: 1.5px;
}

#open-cookies-modal-button:hover{
background-color: var(--color-background-section);
}

@media screen and (min-width: 1024px) {
  .footer-link:not(:first-of-type):before {
    content: "";
    height: 80%;
    width: 2px;
    background-color: var(--color-blue);
    margin-right: 28px;
    border-radius: 2px;
  }

  .hero-columns{
    grid-template-columns: 1.25fr 1fr 1fr;
  }
}