@font-face{
    font-family: "Roboto";
    src: url("../../fonts/roboto-latin-400.479970ff.woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: "Roboto";
    src: url("../../fonts/roboto-latin-500.020c97dc.woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: "Roboto";
    src: url("../../fonts/roboto-latin-700.2735a3a6.woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}