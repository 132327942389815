.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .modal-close {
    position: absolute;
    top: 25px;
    right: 25px;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }