  #spinner-container {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      display: grid;
      width: 100%;
      height: 100vh;
      place-items: center;
      background: var(--color-blue);
  }

  #loading-spinner {
      width: 60px;
      height: 60px;
      position: relative;
      margin: 50px auto;
      border: 3px solid #f3f3f3;
      border-radius: 50%;
      border-top-color: var(--color-background-section);
      animation: spin 1s linear infinite;
  }

  .loading-dots{
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    padding: 6px 0;
    transform: translateY(5px);
  }

  .loading-line {
      width: 1rem;
      height: 1rem;
      border-radius: 15px;
      background-color: white;

  }

  .loading-dots .loading-line:nth-last-child(1) {
      animation: loadingDots 0.6s 0.1s linear infinite;
  }

  .loading-dots .loading-line:nth-last-child(2) {
      animation: loadingDots 0.6s 0.2s linear infinite;
  }

  .loading-dots .loading-line:nth-last-child(3) {
      animation: loadingDots 0.6s 0.3s linear infinite;
  }




  @keyframes loadingDots {
      0% {
          transform: translate(0, 0);
      }

      50% {
          transform: translate(0, -10px);
      }

      100% {
          transform: translate(0, 0);
      }
  }



  @keyframes spin {
      0% {
          transform: rotate(0deg);
      }

      100% {
          transform: rotate(360deg);
      }
  }