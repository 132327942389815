.cookies-modal-info {}

.cookies-modal-info-article .title {
    grid-area: title;
}

.cookies-modal-info-article .description {
    grid-area: description;
}

.cookies-modal-info-article .button {
    grid-area: button;
}


.cookies-modal-info-article {
    grid-template-columns: 1fr auto;
    grid-template-areas:
        "title button"
        "description button";
}

@media screen and (min-width: 1024px) {
    .cookies-modal-info-article {
        grid-template-columns: 128px 1fr 128px;
        grid-template-areas: "title description button";
    }
}